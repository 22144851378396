function About() {
  return (
    <div style={{width: '100%', minHeight: '2500px'}}>
      <div className="about-container">
        <div className="about-left"></div>
        <div className="about-right">
        <h2>Welcome to my about page</h2>
        <p style={{fontSize: '20px', color: 'black', lineHeight: '1.6em'}}>Welcome to PrisDean.com, the cornerstone of unparalleled cleaning services, where dedication meets quality. I'm Dean Lark, the proud owner and heart behind our operations. My journey is anything but ordinary, woven with diverse experiences that have shaped the ethos of our business.
        </p>
        <p style={{fontSize: '20px', color: 'black', lineHeight: '1.6em'}}>Before embarking on the entrepreneurial path, I spent a significant chapter of my life serving with distinction in the armed forces. This formative period instilled in me the virtues of discipline, resilience, and a profound commitment to excellence—qualities that I've carried forward into every aspect of my business.
        </p>
        <p style={{fontSize: '20px', color: 'black', lineHeight: '1.6em'}}>
        After my service, my thirst for adventure and growth led me to the vibrant shores of Thailand, where I dedicated seven years to teaching English. This experience was more than just a job; it was a journey into the heart of different cultures, languages, and perspectives. It taught me the power of communication, patience, and adaptability—skills that are invaluable in understanding and meeting our clients' needs.
        </p>
        <p style={{fontSize: '20px', color: 'black', lineHeight: '1.6em'}}>
        My love for exploration didn't stop there. Australia and Southeast Asia became my playgrounds, where I immersed myself in diverse environments, from bustling cities to serene landscapes. These travels not only enriched my soul but also broadened my understanding of the world, allowing me to bring a unique perspective to my business and to the services we provide.
        </p>
        <p style={{fontSize: '20px', color: 'black', lineHeight: '1.6em'}}>
        When I'm not steering the ship at PrisDean.com, you can find me in the gym, pushing my limits. I'm also deeply passionate about web development. This interest has not only led me to create this website but also continuously drives me to innovate and improve our online presence, ensuring a seamless experience for our clients.
        </p>
        <p style={{fontSize: '24px', color: 'black', lineHeight: '1.6em'}}>
        At PrisDean, we're more than just a cleaning service; we're a team of dedicated professionals who bring a wealth of experience, a global perspective, and a personal touch to every job we undertake. Thank you for considering us. Let's make your space shine together.
        </p>
        <p style={{fontSize: '24px', color: 'black', lineHeight: '1.6em'}}>
        Dean Lark
        </p>
        </div>
      </div>
    </div>
  )
}

export default About
