import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { contactEmail, reset } from '../features/user/userSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Spinner from '../components/Spinner';

function Contact() {
  const { user, isError, isSuccess, isLoading, message } = useSelector(
    (state) => state.user,
  );

  const [formData, setFormData] = useState({
    name: user ? user.data.name : '',
    email: user ? user.data.email : '',
    tel: user ? user.data.tel : '',
    textArea: '',
  });

  const { name, email, tel, textArea } = formData;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }

    if (isSuccess) {
      toast.success(message.message);
      navigate('/');
    }

    dispatch(reset());
  }, [isError, isSuccess, message, navigate, dispatch]);

  const onChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    dispatch(contactEmail(formData));
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div>
      <div  
        style={{ width: '100%', height: '600px' }}
        className="calender-container">
        <div className="contact-form-container">
          <h1>Contact Us</h1>
          <form className="contact" onSubmit={onSubmit}>
            <div className="form-group">
              <label htmlFor="name">Enter your name: </label>
              <input type="text" name="name" value={name} onChange={onChange} />
            </div>

            <div className="form-group">
              <label htmlFor="email">Enter your email: </label>
              <input
                type="email"
                name="email"
                value={email}
                onChange={onChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="tel">Enter your mobile No: </label>
              <input type="tel" name="tel" value={tel} onChange={onChange} />
            </div>

            <div className="form-group">
              <textarea
                name="textArea"
                id="textArea"
                value={textArea}
                onChange={onChange}>
                Please Enter Your Message
              </textarea>
            </div>

            <div className="form-group">
              <button className="btn btn-primary">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
