import Register from '../Register'

function AfterHeader() {
  return (
      <div className="afterHeader">
        <div className="info d-flex justify-content-center align-items-center text-center">
          <p className="display-6">
          Your peace of mind starts with our pristine clean. Dive into a world of cleanliness where every detail matters. Let us sweep you off your feet!
          </p>
        </div>

        <div className="afterHead-right">
          <div id="target-register" className="formContainer">
            <h2>Register With Us</h2>
            <Register />
          </div>
        </div>
      </div>
  );
}

export default AfterHeader
